import React, { useEffect, useState } from 'react'

import {
  Flex,
  Text,
  Button,
  Link
} from '@chakra-ui/react'

const Song = (props) => {
  const [song, setSong] = useState(props.song)
  const [key, setKey] = useState(props.key)
  // useEffect(() => {
  //   for (var i = 0; i < song.artists.length; i++) {
  //     var artistStr = ''
  //     artistStr += song.artists[i].name 
  //     artistStr += ' '
  //   }  
  // })
  return (
    <>
      {/* {key == 0
        ?
        <>
          <Text color="white">{props.key}</Text>
          <Text color="gray.50">Arist</Text>
          <Text color="gray.50">Title</Text>
        </> : <Text color='white'>{key}</Text>
        } */}
      <Flex pr={5} pl={5} h={65} _hover={{ backgroundColor: 'blue.900' }} mb={10} w='100%' justifyContent='space-between' color='white' flexDir='row'>

        <Flex alignSelf='center' flexDir='column'>
          <Link href={song.spotifylink} fontWeight='700' display={['none', 'none', 'flex', 'flex']} >{song.track.name}</Link>
          {
            song.track.name.length > 26
              ?
              <>
                <Link href={song.spotifylink} fontWeight='700' display={['flex', 'flex', 'none', 'none']} w={['100%', '40%', '50%', '70%']}>{song.track.name.substring(0, 26)}...</Link>
              </>
              :
              <>
                <Link href={song.spotifylink} fontWeight='700' display={['flex', 'flex', 'none', 'none']} w={['35%', '30%', '50%', '70%']}>{song.track.name}</Link>
              </>
          }
          {/* {song.artists.map((data, index) => (
            <Text>{data.name}</Text>
          ))} */}
          <Flex flexDir='row'>
            {song.track.artists.map((data, index) => (
              <>
              <Link href={data.external_urls.spotify} fontWeight='200'>{data.name}</Link>
              {index < song.track.artists.length-1
              ? <Text>, &nbsp;</Text>
              :null
              }
              </>
            ))}
          </Flex>
        </Flex>
        {/* <Text alignSelf='center' display={['flex', 'flex', 'flex', 'flex']}>{song.artist}</Text> */}
        <Flex>

        </Flex>
        {/* <Text alignSelf='center' display={['none', 'flex', 'flex', 'flex']}>{song.duration}</Text> */}
        <Button alignSelf='center' rounded={0} _hover={{ color: '#035486', backgroundColor: 'gray.50' }} backgroundColor="#035486" color='white'>Add</Button>
      </Flex>
    </>
  )
}

export default Song