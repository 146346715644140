import React, { useEffect, useState } from 'react'

import {
  Box,
  Flex,
  List,
  Text,
  ListItem,
  Image,
  Button
} from '@chakra-ui/react'

import Nav from '../Components/Nav'

import axios from 'axios'

import Song from '../Components/Song/Song'

import { useHistory } from 'react-router-dom'

const Playlist = props => {
  const history = useHistory()
  const handleBack = () => {
    history.goBack()
  }
  return (
    <>
      <Flex flexDir='row' >
        <Nav playlists={true} />
        <Box overflow='auto' background='#020B22' h='100vh' w='100%'>
          <Button onClick={handleBack} ml={['5%', '4%', '3%', '2%']} mt='1%' w={[50, 50, 100, 100]} h={[26, 26, 30, 30]} rounded={25} _hover={{ bgGradient: "linear(to-r, red.500, yellow.500)" }} color='white' bgGradient="linear(to-r, #009EFF, #045182)">Back</Button>
          <>

            <Display props={props} />
          </>
          {/* {playlist
            ? <Text color='white'>{playlist.images.length}</Text>
            :null
          } */}
        </Box>

      </Flex>

    </>
  )
}

const Display = (props) => {
  const [playlist, setPlaylist] = useState([])
  const { match: { params } } = props;
  useEffect(() => {
    const main = async () => {
      const headers = {
        headers: {
          'authorization': `${localStorage.getItem('token')}`
        }
      }

      await axios.post('http://192.168.1.219:5000/api/playlist/' + params.platform + '/' + params.id, {}, headers).then(res => {
        setPlaylist(res.data.results)
      })
    }

    main()
  }, [setPlaylist, params.id, params.platform])
  return (
    <>
      <Flex flexDir='column' mt='5%' align='center'>
        <Image fallbackSrc="https://via.placeholder.com/150" w={[100, 200, 200, 200]} h={[100, 200, 200, 200]} rounded={5} src={playlist.images[0].url} />
        <Text fontSize={30} color='white' fontWeight={200}>{playlist.name}</Text>
      </Flex>
      <List pl={0} pr={0} w='100%'>
        {playlist.tracks.items.map((data, index) => (
          <>
            <ListItem >

              <Song song={data} key={index} />
            </ListItem>
          </>
        ))}
      </List>
    </>
  )
}

//

export default Playlist
