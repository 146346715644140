import React, { useState, useEffect } from 'react'

import {
  Box,
  Flex,
  Text
} from '@chakra-ui/react'

import Nav from '../Components/Nav'
import SearchBar from '../Components/SearchBar'
import Songs from '../Components/Song/Songs'

const Playlist = (props) => {
  const [songs, setSongs] = useState([])

  useEffect(() => {
    setSongs(props.songs)
  }, [])

  const callback = (data) => {
    // alert(data.length)
    setSongs(data)
  }

  return (
    <>
      <Flex flexDir='row' >
        <Nav songs={true} />
        <Box overflow='auto' background='#020B22' h='100vh' w='100%'>
          {/* <SearchBar callback={callback} placeholder="Search music" endpoint='song/search/' /> */}
          {songs
          ?<Songs songs={songs} />
          : null}
        </Box>
      </Flex>
    </>
  )
}

export default Playlist