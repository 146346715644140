import React, { useState } from 'react'

import {
  InputGroup,
  Input,
  InputLeftElement,
  Box,
  Flex
} from '@chakra-ui/react'

import {
  SearchIcon
} from '@chakra-ui/icons'

import axios from 'axios'

const SearchBar = (props) => {
  const { endpoint, params } = props
  const [search, setSearch] = useState('')

  const handleSearch = async (event) => {
    event.preventDefault();
    if (search !== '') {
      const headers = {
        headers: {
          'authorization': `${localStorage.getItem('token')}`
        }
      }
      await axios.post('http://localhost:5000/api/' + endpoint + search, {}, headers).then(res => {
        props.callback(res.data.results)
      })
    }
  }

  const handleChange = (event) => {
    const value = event.target.value
    setSearch(value)
  }
  return (
    <>
      <Flex ml='13%' mt='5%' width={['80%', '100%', '80%', '80%']}>
        <Flex w={[300, 365, 730, 605, 730]}>
          <form onSubmit={handleSearch}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input name='search' value={search} onChange={handleChange} color='white' type="text" placeholder={props.placeholder} backgroundColor='#035486' borderColor='#035486' />
            </InputGroup>
          </form>
        </Flex>
      </Flex>
    </>
  )
}

export default SearchBar