import React, { useState, useEffect } from 'react'

import {
  SimpleGrid,
  Flex,
  Text
} from '@chakra-ui/react'

import Playlist from './Playlist'

// import ReactJkMusicPlayer from 'react-jinke-music-player'
// import 'react-jinke-music-player/assets/index.css'

import axios from 'axios'
const Playlists = () => {
  const [playlists, setPlaylists] = useState([])
  useEffect(() => {
    const main = async () => {
      const headers = {
        headers: {
          'authorization': `${localStorage.getItem('token')}`
        }
      }
      await axios.post('https://3a254cbf484b.ngrok.io/api/playlist/spotifyall', {
        platform: 'spotify'
      }, headers).then(res => {
        setPlaylists(res.data.results)
      })
    }

    main()
  }, [])

  return (
    <>
      <Flex mt='10%' ml={'8%'} mb='5%' right={900}>
        <SimpleGrid columns={[2, 2, 3, 2, 4]} spacingX={50} spacingY={20}>
          {playlists.map((data, index) => (
            <>
              <Playlist data={data} key={index} />
            </>
          ))}
        </SimpleGrid>
      </Flex>
    </>
  )
}

export default Playlists