import React, { useEffect, useState } from 'react'

import {
  Flex,
  List,
  ListItem,
  Text
} from '@chakra-ui/react'

import Song from './Song'

const Songs = (props) => {
  const [songs, setSongs] = useState([])
  useEffect(() => {
    setSongs(props.songs)
  }) 
  return (
    <>
      <Flex flexDir='column' mt='0%'>
        <Flex pr={10} pl={20} h={50}  mb={10} w='100%' justifyContent='space-between'  flexDir='row'>
          {/* <Text  color='gray.400'>Title</Text> */}
          {/* <Text mr={['-11%', '-11%', '-38%', '-38%']} color='gray.400'>Artists</Text> */}
          {/* <Text mr={['-10%', '-18%', '-46%', '-47%']} color='gray.400' display={['none', 'flex', 'flex', 'flex']}>Duration</Text> */}
          {/* <Text mr={['9%', '3%', '3%', '3%']}  color='gray.400'>Playlist</Text> */}
        </Flex>
          <List pl={0} pr={0} w='100%'>
            <Text>{songs.length}</Text>
            {songs.map((data, index) => (
              <>
                <ListItem >
                  <Song song={data} key={index} />
                </ListItem>
              </>
            ))}
          </List>
        </Flex>
    </>
  )
}

export default Songs