import React from 'react'

import {
  Text,
  Box,
  Flex,
  Link,
  Input,
  Button
} from '@chakra-ui/react'

import { useHistory } from "react-router-dom";

import axios from 'axios'
import SpotifyLogin from 'react-spotify-login';

const Login = () => {
  const history = useHistory()
  const handleLogin = async (res) => {
    axios.post('https://3a254cbf484b.ngrok.io/api/user/spotifylogin', { res })
    localStorage.setItem('token', res.access_token)
    history.push('/playlists')
  }
  const onSuccess = response => alert(response);
  const onFailure = response => console.error(response);

  return (
    <>
      <Box width="100%" height="100vh">
        <Flex display={["none", "none", "flex", "flex"]} justifyContent="center" height="100vh" overflow="auto" width="50%" bgGradient="linear(to-b, #020B22 50%, #011F42)" >
          <Box pos="absolute" top={["25%", "25%", "25%", "25%"]} width="30%">
            <Text fontWeight='200' textAlign="center" fontSize={[80, 30, 50, 80]} color="white">Welcome Please Signin</Text>
            <Flex justifyContent="center">
              <Text fontWeight="700" fontSize={20} color="white">
                New User?{" "}
                <Link href="/login" fontWeight="700" fontSize={20} color="#009EFF">Signup</Link>
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex overflow='auto' width={["100%", '100%', '100%', '50%']} height="100vh" backgroundColor="#020B22" pos="absolute" top="0" right="0">
          <Flex pos="absolute" left="25%" w='50%' top="5%" display={["flex", "flex", "flex", "none"]}>
            <Text fontWeight='200' textAlign="center" fontSize={[40, 40, 50, 80]} color="white">Welcome Please Signin</Text>
          </Flex>
          <Box top={['40%', '40%', '40%', '30%']} pos="absolute" left="15%" width="70%" justifyContent="center" >
            {/* <Input color="white" placeholder="Email" variant="flushed" size="lg" />
            <Input type="password" color="white" mt="15%" placeholder="Password" variant="flushed" size="lg" />
            <Button onClick={handleLogin} marginBottom='10%' ml={["35%", '35%', '40%', '40%']} _hover={{ bgGradient: "linear(to-r, red.500, yellow.500)" }} w={['30%', '30%', '20%', '20%']} alignContent='center' color="white" bgGradient="linear(to-r, #009EFF, #045182)" mt="10%">Login</Button> */}
            <SpotifyLogin clientId={"7a93fbe7b2d744ceb6821d128ecb707c"}
              redirectUri={"https://topsecretprojectthatnoonecanknowaboutfornow.eliaswambugu.com/playlists"}
              onSuccess={handleLogin}
              className="spotify"
              scope='playlist-read-private playlist-modify-public playlist-modify-private user-read-email'
              onFailure={onFailure} />
          </Box>
        </Flex>

      </Box>

    </>
  )
}

export default Login