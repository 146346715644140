import React, { useState, useEffect } from 'react'

import {
  Text,
  Box,
  Flex,
  Link,
  Button,
  Image,
  Icon,
  LinkOverlay,
  LinkBox
} from '@chakra-ui/react'

import {
  FiPlus,
  FiFolderPlus,
  FiDisc,
  FiMusic,
  FiUsers,
  FiUser,
} from "react-icons/fi";

import '../App.css'

import profilePlaceholder from '../assets/images/profilePlaceholder.jpg'

import axios from 'axios'

const Nav = (props) => {
  const { playlists, songs, profile } = props
  const [impPlaylist, setImpPlaylist] = useState(false)
  const [playlist, setPlaylist] = useState(false)
  const [song, setSong] = useState(false)
  const [user, setUser] = useState({
    name: '',
  })
  useEffect(() => {
    const main = async () => {
      const headers = {
        headers: {
          'authorization': `${localStorage.getItem('token')}`
        }
      }
      await axios.post('http://localhost:5000/api/user/me', {}, headers).then(res => {
        if (res.data) {
          setUser(res.data.me)
        }
      })
    }
    main()
  }, [])
  return (
    <>
      <div class='hidenav'>
        <Box overflowY='scroll' pos='relative' w={300} h='100vh' bgGradient="linear(to-b, #020B22 50%, #011F42)" display={['none', 'none', 'none', 'flex']}>
          <Flex ml='5%' mt='5%' h={70} flexDir='row'>
            <Image fallbackSrc="https://via.placeholder.com/150" src={profilePlaceholder} w={20} h={20} rounded={50} alt="Segun Adebayo" />
            <Flex align='center'>
              <Text ml='20%' fontSize={25} color='white'>{user.name}</Text>
            </Flex>
          </Flex>
          <Flex w='70%' color='white' fontSize={18} overflow='auto' top={150} pos='absolute' left={45} flexDir="column">
            {/* <Flex >
              <Icon w={7} h={7} as={FiPlus} />
              <Text ml='5%' mb={10}>Create Playlist</Text>
            </Flex> */}
            {/* <Flex >
              <Icon w={7} h={7} as={FiFolderPlus} />
              <Text ml='5%' mb={10}>Import Playlist</Text>
            </Flex> */}
            {playlists
              ?
              <>
                {/* <Link href='/playlists' color="#009EFF"> */}
                <Flex color="#009EFF" flexDir='row'>
                  <Icon w={7} h={7} as={FiDisc} />
                  <Text ml='5%' >Playlists</Text>
                </Flex>
                {/* </Link> */}
                <Flex mb={10}></Flex>
              </>
              :
              <>
                <Link href='/playlists' color="white">
                  <Flex flexDir='row'>
                    <Icon w={7} h={7} as={FiDisc} />
                    <Text ml='5%' >Playlists</Text>
                  </Flex>
                </Link>
                <Flex mb={10}></Flex>
              </>
            }
            {/* {songs
              ?
              <>
                <Link href='/songs' color="#009EFF">
                  <Flex flexDir='row'>
                    <Icon w={7} h={7} as={FiMusic} />
                    <Text ml='5%' >Songs</Text>
                  </Flex>
                </Link>
                <Flex mb={10}></Flex>
              </>
              :
              <>
                <Link href='/songs' color="white">
                  <Flex flexDir='row'>
                    <Icon w={7} h={7} as={FiMusic} />
                    <Text ml='5%' >Songs</Text>
                  </Flex>
                </Link>
                <Flex mb={10}></Flex>
              </>
            } */}
            <Flex >
              <Icon w={7} h={7} as={FiUsers} />
              <Text ml='5%' mb={10}>Friends</Text>
            </Flex>
            {profile
              ?
              <>
                {/* <Link href='/playlists' color="#009EFF"> */}
                <Flex color="#009EFF" flexDir='row'>
                  <Icon w={7} h={7} as={FiUser} />
                  <Text ml='5%' >Profile</Text>
                </Flex>
                {/* </Link> */}
                <Flex mb={10}></Flex>
              </>
              :
              <>
                <Link href='/me' color="white">
                  <Flex flexDir='row'>
                    <Icon w={7} h={7} as={FiUser} />
                    <Text ml='5%' >Profile</Text>
                  </Flex>
                </Link>
                <Flex mb={10}></Flex>
              </>
            }
          </Flex>

          {/* <Button color="white" _hover={{ bgGradient: "linear(to-r, red.500, yellow.500)" }} bgGradient="linear(to-r, #009EFF, #045182)" left={10} pos='absolute' bottom={5}>
          Connect Platform
          </Button> */}
          {/* <box height="100vh" overflow='auto' flexDir='column' display={['none', 'none', 'none', 'flex']} w={500} bgGradient="linear(to-b, #020B22 25%, #011F42)">
          
        </box> */}
        </Box >
      </div>
    </>
  )
}

export default Nav

  // < Flex ml = '5%' mt = '5%' flexDir = 'row' >
  //           <Image src="https://bit.ly/sage-adebayo" w={20} h={20} rounded={50} alt="Segun Adebayo" />
  //           <Text alignSelf='center' ml='5%' fontSize={25} color='white'>Elias</Text>
  //         </Flex >
  //         <Flex w='50%' color='white' fontSize={18} overflow='auto' top={150} pos='absolute' left={45} flexDir="column">
  //           <Flex >
  //             <Icon w={7} h={7} as={FiPlus} />
  //             <Text ml='1%' mb={10}>Create Playlist</Text>
  //           </Flex>
  //           <Flex >
  //             <Icon w={7} h={7} as={FiFolderPlus} />
  //             <Text ml='1%' mb={10}>Import Playlist</Text>
  //           </Flex>
  //           <Flex >
  //             <Icon w={7} h={7} as={FiDisc} />
  //             <Text ml='1%' mb={10}>Playlists</Text>
  //           </Flex>
  //           <Flex >
  //             <Icon w={7} h={7} as={FiMusic} />
  //             <Text ml='1%' mb={10} >Songs</Text>
  //           </Flex>
  //           <Flex >
  //             <Icon w={7} h={7} as={FiUser} />
  //             <Text ml='1%' mb={10}>Friends</Text>
  //           </Flex>
  //         </Flex>
  //         <Button color="white" _hover={{ bgGradient: "linear(to-r, red.500, yellow.500)" }} bgGradient="linear(to-r, #009EFF, #045182)" left={10} pos='absolute' bottom={5}>
  //   Connect Platform
  //         </Button>



