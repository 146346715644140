import React from 'react'

import {
  Box,
  Flex,
} from '@chakra-ui/react'

import Nav from '../Components/Nav'

const Me = () => {
  return (
    <>
      <Flex flexDir='row' >
        <Nav profile={true} />
        <Box overflow='auto' background='#020B22' h='100vh' w='100%'>
        </Box>
      </Flex>
    </>
  )
}

export default Me
