import React from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Playlists from './Containers/Playlists'
import Playlist from './Containers/Playlist'
import Login from './Containers/Login'
import Songs from './Containers/Songs'
import Me from './Containers/Me'

const App = () => {
  return (
    <>
      <Router>
        <Switch>
        <Route exact path='/me' render={(props) => <Me {...props} />} />
          <Route exact path='/playlists' render={(props) => <Playlists {...props} />} />
          <Route exact path='/playlist/:platform/:id' render={(props) => <Playlist {...props} />} />
          <Route exact path='/songs' render={(props) => <Songs {...props} />} />
          <Route exact path='/login' render={(props) => <Login {...props} />} />
          {/* <Route component={PageNotFound} /> */}
        </Switch>
      </Router>
    </>
  )
}

export default App