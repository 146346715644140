import React, { useState } from 'react'

import {
  Flex,
  Box,
  Text,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Input
} from '@chakra-ui/react'

import {
  SearchIcon
} from '@chakra-ui/icons'

import {useHistory} from 'react-router-dom'

import Tilt from 'react-tilt'

import playlistPlaceholder from '../../assets/images/playlistPlaceholder.png'
import profilePlaceholder from '../../assets/images/profilePlaceholder.jpg'

const Playlist = (props) => {
  const history = useHistory()
  // eslint-disable-next-line
  const [playlist, setPlaylist] = useState(props.data)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [scrollBehavior, setScrollBehavior] = useState("inside")
  const [friends, setFriends] = useState([
    {
      name: 'Elias Wambugu',
      platform: 'Spotify'
    },
    {
      name: 'Elias Wambugu',
      platform: 'Spotify'
    },
    {
      name: 'Elias Wambugu',
      platform: 'Spotify'
    },
    {
      name: 'Elias Wambugu',
      platform: 'Spotify'
    },
    {
      name: 'Elias Wambugu',
      platform: 'Spotify'
    },
    {
      name: 'Elias Wambugu',
      platform: 'Spotify'
    },
    {
      name: 'Elias Wambugu',
      platform: 'Spotify'
    },
    {
      name: 'Elias Wambugu',
      platform: 'Spotify'
    },
    {
      name: 'Elias Wambugu',
      platform: 'Spotify'
    },
    {
      name: 'Elias Wambugu',
      platform: 'Spotify'
    },
    {
      name: 'Elias Wambugu',
      platform: 'Spotify'
    },
  ])
  const handleClick = () => {
    history.push('/playlist/spotify/' + playlist.id)
  }
  const Invite = () => {
    return (
      <>
        <Modal
          isCentered
          onClose={onClose}
          // finalFocusRef={btnRef}
          isOpen={isOpen}
          scrollBehavior={scrollBehavior}
          size='md'
        >
          <ModalOverlay />
          <ModalContent bgGradient="linear(to-b, #020B22 50%, #011F42)">
            <ModalHeader textAlign='center' color='white'>{playlist.name}
              <Text fontSize={15} fontWeight='200' textAlign='center' color='white'>**Inviting friends will make this playlist public**</Text>
            </ModalHeader>
            <ModalCloseButton color='white' />
            <ModalBody >
              {/* <Lorem size={5} /> */}

              <Flex>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon color="gray.300" />}
                  />
                  <Input w='100%' color='white' type="text" placeholder="Search friends" backgroundColor='#035486' borderColor='#035486' />
                </InputGroup>
              </Flex>
              <Flex flexDir='column' mt='5%' w='100%'>
                {friends.map((data, index) => (
                  <>
                    <Flex w='100%' mb='5%' flexDir='row'>
                      <Image fallbackSrc="https://via.placeholder.com/150" src={profilePlaceholder} w={67} h={45} rounded={50} alt="Segun Adebayo" />
                      <Flex w='60%' align='center'>
                        <Text wordBreak='break-word' ml='5%' color='white'>{data.name}</Text>
                      </Flex>
                      <Text fontWeight='700' color='white'>Platform: {data.platform}</Text>
                      <Button _hover={{color: '#035486', backgroundColor: 'gray.50'}} w={100} ml='5%' rounded={0} backgroundColor="#035486" color='white'>
                        Invite
                      </Button>
                    </Flex>
                  </>
                ))}
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button color='white' bgGradient="linear(to-r, #009EFF, #045182)" _hover={{ bgGradient: "linear(to-r, red.500, yellow.500)" }} onClick={onClose}>Done</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
  return (
    <>
      <Invite />
      <Box color='white' >
        <Tilt className="Tilt" options={{ max: 25 }}>
          <Flex onClick={handleClick} _hover={{cursor: 'pointer'}} justifyContent='center'>
            <Image fallbackSrc="https://via.placeholder.com/150" src={playlist.images[0].url} w={[100, 200, 200, 200]} h={[100, 200, 200, 200]} rounded={5} alt="Segun Adebayo" />
          </Flex>
        </Tilt>
        <Text  textAlign='center' w='100%' fontSize={[20, 20, 30, 30]} mt='5%' fontWeight='700'>{playlist.name}</Text>
        {playlist.collaborative
        ? <Text textAlign='center' fontWeight='200'>Public</Text>
        : <Text textAlign='center' fontWeight='200'>Private</Text>
        }
        {/* <Flex mt='5%' justifyContent='center'>
          <Button  onClick={onOpen} fontSize={20} w={[100, 100, 150, 150]} h={[26, 26, 30, 30]} rounded={25} justifySelf='center' _hover={{ bgGradient: "linear(to-r, red.500, yellow.500)" }} bgGradient="linear(to-r, #009EFF, #045182)">Invite</Button>
        </Flex> */}
      </Box>
    </>
  )
}

export default Playlist