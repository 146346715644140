import React from 'react'

import {
  Box,
  Flex,
} from '@chakra-ui/react'

import Nav from '../Components/Nav'
import SearchBar from '../Components/SearchBar'
import PlaylistList from '../Components/Playlist/PlaylistList'

const Playlists = () => {
  return (
    <>
      <Flex flexDir='row' >
        <Nav playlists={true} />
        <Box overflow='auto' background='#020B22' h='100vh' w='100%'>
          <SearchBar endpoint='playlist/search' placeholder='Search playlists' params='?keyword=' />
          <PlaylistList />
        </Box>
      </Flex>
    </>
  )
}

export default Playlists